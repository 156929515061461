





















































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import AttributeTabs from '@/components/decorate/attribute-tabs.vue'
import ColorSelect from '@/components/decorate/color-select.vue'
import StyleChose from '@/components/decorate/style-chose.vue'
import AttributeItem from '@/components/decorate/attribute-item.vue'
import MaterialSelect from '@/components/material-select/index.vue'
import Slider from '@/components/decorate/slider.vue'
import LinkSelect from '@/components/link-select/index.vue'
import Draggable from 'vuedraggable'
@Component({
    components: {
        AttributeTabs,
        ColorSelect,
        StyleChose,
        Slider,
        AttributeItem,
        MaterialSelect,
        LinkSelect,
        Draggable
    }
})
export default class SearchAttribute extends Vue {
    /** S data **/

    limit = 10
    /** E data **/

    /** S computed **/

    get content() {
        return this.$store.getters.content
    }

    get styles() {
        return this.$store.getters.styles
    }

    /** E computed **/

    handleAdd() {
        if (this.content.data.length < this.limit) {
            this.content.data.push({
                url: '',
                title: '标题名称',
                title_color: '#333333',
                subtitle: '副标题名称',
                subtitle_color: '#666666',
                link: {},
                bg_color: '#FFFFFF'
            })
        } else {
            this.$message.warning(`最多添加${this.limit}条`)
        }
    }
    handleDelete(index: number) {
        if (this.content.data.length <= 1) {
            return
        }
        this.content.data.splice(index, 1)
    }
}





















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import AttributeTabs from '@/components/decorate/attribute-tabs.vue'
import ColorSelect from '@/components/decorate/color-select.vue'
import StyleChose from '@/components/decorate/style-chose.vue'
import AttributeItem from '@/components/decorate/attribute-item.vue'
import Slider from '@/components/decorate/slider.vue'
@Component({
    components: {
        AttributeTabs,
        ColorSelect,
        StyleChose,
        Slider,
        AttributeItem
    }
})
export default class SearchAttribute extends Vue {
    /** S data **/

    borderData = [
        {
            name: '直角',
            value: 1
        },
        {
            name: '圆角',
            value: 2
        }
    ]
    alignData = [
        {
            name: '居左',
            value: 'left'
        },
        {
            name: '居中',
            value: 'center'
        }
    ]
    resetColor = ''
    /** E data **/

    /** S computed **/

    get content() {
        return this.$store.getters.content
    }

    get styles() {
        return this.$store.getters.styles
    }

    /** E computed **/
}













































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import WidgetRoot from '@/components/decorate/widget-root.vue'
@Component({
    components: {
        WidgetRoot
    }
})
export default class Goods extends Vue {
    @Prop() content!: any
    @Prop() styles!: any

    get lists() {
        const { num } = this.content
        return [...Array(num).keys()].map(() => ({}))
    }

    get headerStyle() {
        const { header_bg_type, header_bg_image } = this.content
        const { header_bg_color } = this.styles
        return header_bg_type == 1
            ? {
                  'background-color': header_bg_color
              }
            : {
                  'background-image': `url(${this.$getImageUri(
                      header_bg_image
                  )})`
              }
    }
}

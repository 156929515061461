

























































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import AttributeTabs from '@/components/decorate/attribute-tabs.vue'
import ColorSelect from '@/components/decorate/color-select.vue'
import StyleChose from '@/components/decorate/style-chose.vue'
import Slider from '@/components/decorate/slider.vue'
import AttributeItem from '@/components/decorate/attribute-item.vue'
import StyleSelect from '@/components/decorate/style-select.vue'
import CouponSelect from '@/components/coupon-select/index.vue'
import MaterialSelect from '@/components/material-select/index.vue'
@Component({
    components: {
        AttributeTabs,
        ColorSelect,
        StyleChose,
        Slider,
        AttributeItem,
        StyleSelect,
        CouponSelect,
        MaterialSelect
    }
})
export default class Attribute extends Vue {
    /** S data **/
    styleData = [
        {
            value: 1,
            img: require('@/assets/images/coupon1.png'),
            text: '风格1'
        },
        {
            value: 2,
            img: require('@/assets/images/coupon2.png'),
            text: '风格2'
        },
        {
            value: 3,
            img: require('@/assets/images/coupon3.png'),
            text: '风格3'
        }
    ]
    /** E data **/

    /** S computed **/

    get content() {
        return this.$store.getters.content
    }

    set content(val) {
        const data = {
            key: 'content',
            value: val
        }
        this.$store.commit('setAttribute', data)
    }
    get styles() {
        return this.$store.getters.styles
    }

    /** E computed **/
    @Watch('content.style')
    styleChange(val: number) {
        switch (val) {
            case 1:
            case 2:
                this.styles.root_bg_color = '#FFFFFF'
                this.styles.bg_color = '#FCE7E7'
                break
            case 3:
                this.styles.root_bg_color = ''
                this.styles.bg_color = '#FFFFFF'
        }
    }
    /** S methods **/

    /** E methods **/
}

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import widgets from '@/components/decorate/widgets'
import { Getter } from 'vuex-class'
import PageInfo from '@/components/decorate/page-info.vue'
import pcWidgets from './pc-widgets'
@Component({
    components: {
        PageInfo
    }
})
export default class DecoratePhone extends Vue {
    @Prop({ default: 'mobile' }) client!: string
    @Prop() config!: any
    @Getter('widgetName') widgetName!: string
    /** S data **/
    widgets = this.client == 'mobile' ? widgets : pcWidgets
    /** E data **/

    /** S computed **/

    get selectIndex() {
        return this.$store.state.decorate.selectIndex
    }
    set selectIndex(val) {
        this.$store.commit('setSelectIndex', val)
    }

    /** E computed **/
}
































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { decorateMenu, pcDecorateMenu } from '@/utils/decorate'
import { Getter } from 'vuex-class'
@Component
export default class DecorateLeft extends Vue {
    @Prop({ default: 'mobile' }) client!: string
    @Getter('widgetName') widgetName!: string
    /** S data **/
    leftMenu = this.client == 'pc' ? pcDecorateMenu : decorateMenu
    /** E data **/

    /** S computed **/

    get dragTarget() {
        return this.$store.state.decorate.dragTarget
    }

    set dragTarget(val) {
        this.$store.commit('setDragTarget', val)
    }

    get dragIndex() {
        return this.$store.state.decorate.dragIndex
    }

    set dragIndex(val) {
        this.$store.commit('setDragIndex', val)
    }

    get widgetData() {
        return this.$store.state.decorate.widgetData
    }

    set widgetData(val) {
        this.$store.commit('setWidgetData', val)
    }

    /** E computed **/

    /** S methods **/
    dragStart(item: any) {
        this.dragTarget = 'widget'
        this.widgetData = item
    }
    dragEnd() {
        this.dragTarget = ''
        this.widgetData = null
        this.dragIndex = -2
    }

    /** E methods **/
}

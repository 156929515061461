





























































import { apiCategoryLists } from '@/api/goods'
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({})
export default class Category extends Vue {
    /** S data **/
    category: any = []
    cateTwo = []
    showCateFloat = false
    selectIndex = -1
    /** E data **/

    /** S methods **/
    getCategoryLists() {
        apiCategoryLists({
            page_type: 1
        }).then(res => {
            this.category = res.lists
        })
    }

    enterCate(index: number) {
        this.cateTwo = this.category[index].sons || []
        this.showCateFloat = true
        this.selectIndex = index
    }
    leaveCate() {
        this.selectIndex = -1
        this.showCateFloat = false
    }
    /** E methods **/

    created() {
        this.getCategoryLists()
    }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('widget-root',{attrs:{"styles":_vm.styles}},[_c('div',{staticClass:"video",class:{
            style1: _vm.content.proportion == 1,
            style2: _vm.content.proportion == 2,
            style3: _vm.content.proportion == 3
        },style:([
            {
                'border-radius': ((_vm.styles.border_radius_top) + "px " + (_vm.styles.border_radius_top) + "px " + (_vm.styles.border_radius_bottom) + "px " + (_vm.styles.border_radius_bottom) + "px")
            },
            _vm.videoStyle
        ])})])}
var staticRenderFns = []

export { render, staticRenderFns }
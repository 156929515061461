
























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
@Component({
    components: {
        LsDialog
    }
})
export default class CategorySelect extends Vue {
    /** S data **/
    @Prop() value!: number
    category = 1
    currentStyle = 0
    /** E data **/

    /** S watch **/
    @Watch('value', { immediate: true })
    valueChange(val: number) {
        switch (val) {
            case 1:
            case 2:
            case 3:
            case 4:
                this.category = 1
                break
            case 5:
            case 6:
                this.category = 2
                break
            case 7:
            case 8:
                this.category = 3
                break
        }
        this.currentStyle = val
    }

    /** E watch **/

    /** S methods **/
    selectStyle(style: number) {
        this.currentStyle = style
    }
    onConfirm() {
        this.$emit('input', this.currentStyle)
    }
    /** E methods **/
}

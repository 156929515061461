


































import { Component, Prop, Vue } from 'vue-property-decorator'
import Indicator from '@/components/decorate/indicator.vue'
import WidgetRoot from '@/components/decorate/widget-root.vue'
@Component({
    components: {
        Indicator,
        WidgetRoot
    }
})
export default class Contents extends Vue {
    @Prop() content!: any
    @Prop() styles!: any

    get getImage() {
        const { data } = this.content
        if (Array.isArray(data)) {
            return data[0] ? this.$getImageUri(data[0].url) : ''
        }
        return ''
    }
}

import search from './search'
import title from './title'
import banner from './banner'
import navigation from './navigation'
import goodsgroup from './goodsgroup'
import goodsrecom from './goodsrecom'
import reviews from './reviews'
import tabs from './tabs'
import category from './category'
import userinfo from './userinfo'
import userorder from './userorder'
import userserve from './userserve'
import notice from './notice'
import blank from './blank'
import separate from './separate'
import rubik from './rubik'
import coupon from './coupon'
import seckill from './seckill'
import spellgroup from './spellgroup'
import video from './video'
import graphic from './graphic'
import mnplive from './mnplive'
export default {
    search,
    title,
    banner,
    navigation,
    category,
    userinfo,
    userorder,
    userserve,
    goodsgroup,
    reviews,
    goodsrecom,
    tabs,
    notice,
    blank,
    separate,
    rubik,
    coupon,
    seckill,
    spellgroup,
    video,
    graphic,
    mnplive
}

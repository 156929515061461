import adv from './adv'
import header from './header'
import footer from './footer'
import fixed from './fixed'
import banner from './banner'
import goodsgroup from './goodsgroup'
import seckill from './seckill'
export default {
    header,
    adv,
    footer,
    fixed,
    banner,
    goodsgroup,
    seckill
}

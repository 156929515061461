





















































import { Component, Prop, Vue } from 'vue-property-decorator'
import Indicator from '@/components/decorate/indicator.vue'
import WidgetRoot from '@/components/decorate/widget-root.vue'
@Component({
    components: {
        Indicator,
        WidgetRoot
    }
})
export default class Contents extends Vue {
    @Prop() content!: any
    @Prop() styles!: any
    get goods() {
        const { data, goods_type, category } = this.content
        if (goods_type == 2) {
            return [...Array(category.num).keys()].map(() => ({}))
        }
        return data.length ? data : [{}, {}, {}, {}, {}]
    }
}

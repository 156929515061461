var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('widget-root',{attrs:{"styles":_vm.styles}},[_c('div',{staticClass:"graphic"},[_c('div',{staticClass:"graphic-list"},_vm._l((_vm.content.data),function(item,index){return _c('div',{key:index,staticClass:"graphic-item flex-col",style:({
                    'border-radius': ((_vm.styles.border_radius_top) + "px " + (_vm.styles.border_radius_top) + "px " + (_vm.styles.border_radius_bottom) + "px " + (_vm.styles.border_radius_bottom) + "px")
                })},[_c('el-image',{staticStyle:{"width":"100%","height":"140px"},attrs:{"src":_vm.$getImageUri(item.url),"fit":"cover"}},[_c('img',{staticClass:"image-error",attrs:{"slot":"error","src":require("@/assets/images/goods_image.png"),"alt":""},slot:"error"})]),_c('div',{staticClass:"info",style:({
                        'background-color': item.bg_color
                    })},[_c('div',{staticClass:"title line-1",style:({
                            color: item.title_color
                        })},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"subtitle line-1",style:({
                            color: item.subtitle_color
                        })},[_vm._v(" "+_vm._s(item.subtitle)+" ")])])],1)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }





















































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import AttributeTabs from '@/components/decorate/attribute-tabs.vue'
import ColorSelect from '@/components/decorate/color-select.vue'
import StyleChose from '@/components/decorate/style-chose.vue'
import Slider from '@/components/decorate/slider.vue'
import AttributeItem from '@/components/decorate/attribute-item.vue'
import CategoryStyle from './category-style.vue'
import StyleList from './style-list.vue'
import MaterialSelect from '@/components/material-select/index.vue'
import LinkSelect from '@/components/link-select/index.vue'
import CategorySelect from '@/components/category-select/dialog.vue'
@Component({
    components: {
        AttributeTabs,
        ColorSelect,
        StyleChose,
        Slider,
        AttributeItem,
        CategoryStyle,
        StyleList,
        MaterialSelect,
        LinkSelect,
        CategorySelect
    }
})
export default class Attribute extends Vue {
    /** S data **/
    showSelect = false
    category = 1
    /** E data **/

    /** S computed **/

    get content() {
        return this.$store.getters.content
    }

    set content(val) {
        const data = {
            key: 'content',
            value: val
        }
        this.$store.commit('setAttribute', data)
    }
    get styles() {
        return this.$store.getters.styles
    }

    /** E computed **/

    /** S methods **/
    handleAdd() {
        if (!Array.isArray(this.content.data)) {
            this.content.data = []
        }
        this.content.data.push({
            url: '',
            link: {},
            category: {}
        })
    }
    handleDelete(index: number) {
        this.content.data.splice(index, 1)
    }

    selectStyle(style: number) {
        this.content.style = style
        this.showSelect = false
    }
    /** E methods **/
}

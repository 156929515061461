var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('widget-root',{attrs:{"styles":_vm.styles}},[_c('div',{staticClass:"mnplive",style:({
            'background-color': _vm.styles.content_bg_color,
            'border-radius': ((_vm.styles.border_radius_top) + "px " + (_vm.styles.border_radius_top) + "px " + (_vm.styles.border_radius_bottom) + "px " + (_vm.styles.border_radius_bottom) + "px")
        })},[_c('div',{staticClass:"mnplive-header flex",style:([_vm.headerStyle])},[_c('div',{staticClass:"flex-1 flex"},[(_vm.content.header_icon_image)?_c('img',{staticClass:"flex-none m-r-5",staticStyle:{"height":"22px"},attrs:{"src":_vm.$getImageUri(_vm.content.header_icon_image)}}):_vm._e(),_c('div',{staticClass:"line-1",style:({
                        'max-width': '200px',
                        color: _vm.styles.header_title_color,
                        'font-size': ((_vm.styles.header_title_size) + "px")
                    })},[_vm._v(" "+_vm._s(_vm.content.header_title)+" ")])]),(_vm.content.show_haeder_more)?_c('div',{staticClass:"more flex-none xs",style:({
                    color: _vm.styles.header_more_color
                })},[_vm._v(" "+_vm._s(_vm.content.header_more_text)+" "),_c('i',{staticClass:"el-icon-arrow-right"})]):_vm._e()]),_c('div',{staticClass:"mnplive-wrap",style:({ margin: ("-" + (_vm.styles.margin / 2) + "px") })},[_c('div',{staticClass:"mnplive-lists",class:{
                    larger: _vm.content.style == 1,
                    perline: _vm.content.style == 2,
                    lists: _vm.content.style == 3
                }},_vm._l((_vm.lists),function(item,index){return _c('div',{key:index,staticClass:"mnplive-item-wrap"},[_c('div',{staticClass:"mnplive-item",style:({
                            'background-color': _vm.styles.bg_color,
                            margin: ((_vm.styles.margin / 2) + "px"),
                            'border-radius': ((_vm.styles.goods_border_radius) + "px")
                        })},[_c('div',{staticClass:"item-image"},[_c('div',{staticClass:"status flex"},[_c('img',{staticClass:"live-img",attrs:{"src":require("@/assets/images/live.gif"),"alt":""}}),_c('span',{staticClass:"m-l-10 xs"},[_vm._v("直播中")])]),_c('div',{staticClass:"name"},[_vm._v("直播间名称")]),_c('el-image',{attrs:{"src":item.image,"fit":"cover"}},[_c('img',{staticClass:"image-error",attrs:{"slot":"error","src":require("@/assets/images/goods_image.png"),"alt":""},slot:"error"})])],1),_c('div',{staticClass:"info"},[_c('div',[_c('span',{staticClass:"lg weight-500"},[_vm._v("主播：主播名")]),(_vm.content.style == 1)?_c('span',{staticClass:"sm"},[_vm._v(" ｜ 直播商品：10件 ")]):_c('div',{staticClass:"sm m-t-10"},[_vm._v(" 直播商品：10件 ")])]),_c('div',{staticClass:"xs muted m-t-10"},[_vm._v(" 开播时间：2021-10-12 15:00 ")])])])])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }
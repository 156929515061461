




























































import { Component, Prop, Vue } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import LinkSelect from '@/components/link-select/index.vue'
import Draggable from 'vuedraggable'
@Component({
    components: {
        MaterialSelect,
        LinkSelect,
        Draggable
    }
})
export default class NavLists extends Vue {
    /** S props **/

    @Prop() value!: any[]
    @Prop({ default: -1 }) limit!: number
    @Prop() client!: string
    @Prop({ default: true }) hasImg!: boolean

    /** E props **/

    /** S data **/

    /** E data **/
    get nav() {
        return this.value
    }

    set nav(val) {
        this.$emit('input', val)
    }

    /** S methods **/
    onAdd() {
        if (this.limit != -1 && this.nav.length >= this.limit) {
            return this.$message.error(`最多添加${this.limit}个`)
        }
        this.nav.push({
            url: '',
            name: '导航',
            link: {}
        })
    }
    onDelete(index: number) {
        this.nav.splice(index, 1)
    }
    /** E methods **/
}

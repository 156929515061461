var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('widget-root',{attrs:{"styles":_vm.styles}},[_c('div',{staticClass:"coupon",style:({ '--bgcolor': _vm.styles.root_bg_color || '#ffffff' })},[(_vm.content.style == 1)?_c('div',{staticClass:"coupon-style1"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"coupon-item"},[_c('div',{staticClass:"coupon-con flex",style:({
                        'background-color': _vm.styles.bg_color,
                        color: _vm.styles.text_color
                    })},[_c('div',{staticClass:"coupon-price flex-col col-center row-center flex-none"},[_c('div',{staticClass:"font-size-20 weight-500"},[_vm._v(" ￥"),_c('span',{staticClass:"font-size-30 money"},[_vm._v(_vm._s(parseFloat(item.money) || '10'))])]),_c('div',{staticClass:"xs"},[_vm._v(" "+_vm._s(item.condition || '满100可用')+" ")])]),_c('div',{staticClass:"coupon-line",style:({
                            'background-image': ("repeating-linear-gradient(" + (_vm.styles.text_color) + ", " + (_vm.styles.text_color) + " 4px, transparent 4px,transparent 8px)")
                        })}),_c('div',{staticClass:"coupon-info flex flex-1"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"lg weight-500 line-1",staticStyle:{"max-width":"130px"}},[_vm._v(" "+_vm._s(item.name || '优惠券名称')+" ")]),_c('div',{staticClass:"xs m-t-5"},[_vm._v(" "+_vm._s(item.use_type || '全场通用')+" ")])]),_c('div',{staticClass:"coupon-btn xs flex-none",style:({
                                'background-color': _vm.styles.btn_bg_color,
                                color: _vm.styles.btn_text_color
                            })},[_vm._v(" 领取 ")])])])])}),0):_vm._e(),(_vm.content.style == 2)?_c('div',{staticClass:"coupon-style2 flex"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"coupon-item flex-none"},[_c('div',{staticClass:"coupon-con flex",style:({
                        'background-color': _vm.styles.bg_color,
                        color: _vm.styles.text_color
                    })},[_c('div',{staticClass:"coupon-price flex-col col-center row-center flex-none"},[_c('div',{staticClass:"font-size-20 weight-500"},[_vm._v(" ￥"),_c('span',{staticClass:"font-size-30 money"},[_vm._v(_vm._s(parseFloat(item.money) || '10'))])]),_c('div',{staticClass:"xs"},[_vm._v(" "+_vm._s(item.condition || '满100可用')+" ")])]),_c('div',{staticClass:"coupon-line",style:({
                            'background-image': ("repeating-linear-gradient(" + (_vm.styles.text_color) + " 0px, " + (_vm.styles.text_color) + " 4px, transparent 4px,transparent 8px)")
                        })}),_c('div',{staticClass:"coupon-btn"},[_vm._v("立即领取")])])])}),0):_vm._e(),(_vm.content.style == 3)?_c('div',{staticClass:"coupon-style3",style:([
                _vm.content.bg_type == 2
                    ? { 'background-image': ("url(" + (_vm.styles.bg_image) + ")") }
                    : {}
            ])},[_c('div',{staticClass:"title white xxl"},[_vm._v(_vm._s(_vm.content.title))]),_c('div',{staticClass:"coupon-list flex"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"coupon-item flex-none"},[_c('div',{staticClass:"coupon-con flex-col col-center row-center",style:({
                            'background-color': _vm.styles.bg_color
                        })},[_c('div',{staticClass:"coupon-price"},[_c('div',{staticClass:"font-size-20 weight-500",style:({ color: _vm.styles.money_color })},[_vm._v(" ￥"),_c('span',{staticClass:"font-size-30 money"},[_vm._v(_vm._s(parseFloat(item.money) || '10'))])])]),_c('div',{staticClass:"xs",style:({ color: _vm.styles.condition_color })},[_vm._v(" "+_vm._s(item.condition || '满100可用')+" ")]),_c('div',{staticClass:"xs m-t-2",style:({ color: _vm.styles.scene_color })},[_vm._v(" "+_vm._s(item.use_type || '全场通用')+" ")]),_c('div',{staticClass:"coupon-btn xs flex-none",style:({
                                'background-color': _vm.styles.btn_bg_color,
                                color: _vm.styles.btn_text_color
                            })},[_vm._v(" 立即领取 ")])])])}),0)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }
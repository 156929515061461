var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"goods-group",style:({
        margin: ("-" + (_vm.styles.margin / 2) + "px"),
        'background-color': _vm.styles.content_bg_color,
        'border-radius': ((_vm.styles.border_radius_top) + "px " + (_vm.styles.border_radius_top) + "px " + (_vm.styles.border_radius_bottom) + "px " + (_vm.styles.border_radius_bottom) + "px"),
        padding: ((_vm.styles.padding / 2) + "px")
    })},[_c('div',{staticClass:"goods-lists",class:{
            larger: _vm.content.style == 1,
            perline: _vm.content.style == 2,
            swiper: _vm.content.style == 3,
            lists: _vm.content.style == 4
        }},_vm._l((_vm.goods),function(item,index){return _c('div',{key:index,staticClass:"goods-wrap"},[_c('div',{staticClass:"goods-item",style:({
                    'background-color': _vm.styles.bg_color,
                    margin: ((_vm.styles.margin / 2) + "px"),
                    'border-radius': ((_vm.styles.goods_border_radius) + "px")
                })},[_c('div',{staticClass:"goods-image"},[_c('el-image',{attrs:{"src":item.image,"fit":"cover"}},[_c('img',{staticClass:"image-error",attrs:{"slot":"error","src":require("@/assets/images/goods_image.png"),"alt":""},slot:"error"})])],1),_c('div',{staticClass:"goods-info p-5"},[(_vm.content.show_title)?_c('div',{staticClass:"m-b-4 name",style:({
                            color: _vm.styles.title_color
                        })},[_c('div',{class:_vm.content.style == 3 ? 'line-1' : 'line-2'},[_vm._v(" "+_vm._s(item.name || '这里是商品标题')+" ")])]):_vm._e(),_c('div',{staticClass:"flex price-btn flex-wrap"},[_c('div',{staticClass:"flex-1 flex col-baseline"}),(
                                _vm.content.show_btn &&
                                (_vm.content.style == 1 || _vm.content.style == 4)
                            )?_c('div',{staticClass:"buy-btn xs",style:([_vm.btnStyle])},[_vm._v(" "+_vm._s(_vm.content.btn_text)+" ")]):_vm._e()])])])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }




























































import { Component, Prop, Vue } from 'vue-property-decorator'
import Indicator from '@/components/decorate/indicator.vue'
import WidgetRoot from '@/components/decorate/widget-root.vue'
import Category from './category.vue'
@Component({
    components: {
        Indicator,
        WidgetRoot,
        Category
    }
})
export default class Contents extends Vue {
    @Prop() content!: any
    @Prop() styles!: any
    get logo() {
        return this.$store.getters.config.logo
    }
}
